<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference pointer-events-none"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div class="absolute inset-0 bg-brand-blue rounded-b-[50px]"></div>
    <div class="text-center relative z-10">
      <h1
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[420px] md:max-w-[789px] leading-tight mb-5 opacity-0 translate-y-8 uppercase text-white mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.4,
              })
            }
          }
        "
      >
        EXCESS FLOOD INSURANCE COVERAGE
      </h1>
      <div
        class="mx-auto max-w-[532px] w-full relative opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.5,
              })
            }
          }
        "
      >
        <p class="text-lg text-white max-w-sm mx-auto">
          Contact your insurance agent or call us at
          <a href="tel:727-217-5343" class="underline">727-217-5343</a> for a
          quote
        </p>
      </div>
    </div>
  </section>
  <section class="prose mx-auto my-24 px-6">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      WHAT IS EXCESS FLOOD INSURANCE?
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      When you buy a flood insurance policy from the National Flood Insurance
      Program (NFIP), you are limited for a home to a maximum of $250,000 in
      building coverage and $100,000 in contents coverage. Businesses are not
      much better, with a
      <a
        href="https://neptuneflood.com/commercial-flood-insurance/"
        title="Commercial Flood Insurance"
        >commercial building coverage</a
      >
      limit of only $500,000. But what if you want a higher dollar coverage? Or
      coverage not offered by the NFIP?
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: 0.2,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      That’s where Neptune’s excess flood insurance comes in. If you have a loss
      that exceeds the NFIP limits, coverage under an excess flood policy begins
      where the NFIP maximum limit ends. Plus, Neptune offers additional
      coverage options on your primary coverage to better protect you.
    </p>
    <h3
      class="font-extrabold uppercase opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: 0.2,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      Excess Benefits
    </h3>
    <ul
      class="opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: 0.2,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      <li>
        All coverage amounts are in excess of the maximum available under the
        NFIP
      </li>
      <li>$0 deductible</li>
      <li>Available in 49 states and Washington, D.C.</li>
      <li>Minimum earned premiums apply</li>
      <li>
        No wait on real estate closings or rollovers from another flood policy.
        Otherwise, 10 days.
      </li>
    </ul>
  </section>
  <section class="mt-32 px-6 lg:px-0">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8 sm:text-center"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      EXCESS COVERAGE LIMITS WITH NEPTUNE
    </h2>
    <p
      class="max-w-3xl mx-auto sm:text-center text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      By choosing Neptune for excess flood insurance, you are making the wise
      decision to protect your property for what it would really cost to
      replace. Our higher limits allow you to choose the coverage you need. All
      excess coverage is on top of the
      <a
        href="https://neptuneflood.com/blog/private-flood-insurance-vs-fema/"
        title="Private Flood Insurance VS. NFIP"
        class="underline"
        >NFIP limits</a
      >.
    </p>
    <div class="grid md:grid-cols-2 gap-4 max-w-[1400px] mx-auto mt-20">
      <AdvantageCard
        :data="cards[0]"
        class="col-span-1 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      />
      <AdvantageCard
        :data="cards[1]"
        class="col-span-1 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.25,
              })
            }
          }
        "
      />
      <AdvantageCard
        :data="cards[2]"
        class="col-span-1 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.3,
              })
            }
          }
        "
      />
      <AdvantageCard
        :data="cards[3]"
        class="col-span-1 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.3,
              })
            }
          }
        "
      />
    </div>
  </section>

  <section class="mt-32 px-6 lg:px-0">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8 sm:text-center"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      OPTIONAL PRIMARY COVERAGES
    </h2>
    <p
      class="max-w-3xl mx-auto sm:text-center text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Neptune offers optional primary coverages that go above and beyond what is
      available through an NFIP policy. Selecting at least the minimum required
      excess building and/or contents coverage is required for optional
      coverages.
    </p>
    <div class="grid md:grid-cols-2 gap-4 max-w-7xl mx-auto mt-20">
      <AdvantageCard
        v-for="coverage in optionalPrimaryCoverages"
        :data="coverage"
        class="col-span-1 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      />
    </div>
  </section>

  <section
    class="mt-12 bg-[#D5EAE8] p-20 rounded-3xl border border-[#6AAFAA] mx-auto max-w-7xl"
  >
    <h2
      class="text-[#375B57] text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8 sm:text-center"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      ENHANCED EXCESS COVERAGE
    </h2>
    <div class="grid lg:grid-cols-3 gap-8">
      <div class="text-[#375B57]">
        <p><strong>Residential</strong></p>
        <ul
          class="text-[#375B57] max-w-3xl mx-auto opacity-0 translate-y-8 list-disc"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <li>Replacement Cost on Contents</li>
          <li>Building Betterments & Improvements</li>
        </ul>
      </div>
      <div class="text-[#375B57]">
        <p><strong>Commercial</strong></p>
        <ul
          class="text-[#375B57] max-w-3xl mx-auto opacity-0 translate-y-8 list-disc"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <li>Replacement Cost on Building</li>
          <li>Building Betterments & Improvements</li>
        </ul>
      </div>
      <div class="text-[#375B57]">
        <p><strong>Apartments</strong></p>
        <ul
          class="text-[#375B57] max-w-3xl mx-auto opacity-0 translate-y-8 list-disc"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <li>Replacement Cost on Building</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="my-20 md:my-40 px-6 md:px-0">
    <div class="max-w-7xl mx-auto rounded-3xl relative z-10">
      <h2
        class="text-[24px] md:text-[32px] sm:text-center uppercase font-extrabold max-w-[700px] mx-auto mb-4 leading-tight opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        LEARN MORE & GET COVERED
      </h2>
      <div class="grid md:grid-cols-3 gap-4 max-w-[1400px] mx-auto mt-8">
        <AdvantageCard
          v-for="card in learnMoreCards"
          :data="card"
          class="col-span-1 opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        />
      </div>
    </div>
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.excess_faqs?.headline || 'Frequently asked questions' }}
    </h2>
    <FAQs
      :data="{
        faqs: data?.excess_faqs?.questions_and_answers || excessFaqs,
      }"
    />
  </section>

  <CallToActionExcess />
</template>
<script setup>
import { Autoplay } from 'swiper/modules'
import { ArrowRightIcon, StarIcon } from '@heroicons/vue/20/solid'
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToActionExcess from '@/components/CallToActionExcess.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'
import AdvantageCard from '@/components/AdvantageCard.vue'
import StandardCard from '@/components/StandardCard.vue'
import Testimonials from '@/components/Testimonials.vue'
import FAQs from '@/components/FAQs.vue'
import { excessFaqs } from '@/data/faqs.js'

import { reactive, ref } from 'vue'

const { data } = defineProps(['data'])
const benefits = [
  'Medical insurance',
  '401(k) Match',
  'Dental Insurance',
  'Unlimited PTO',
  'Vision Insurance',
  'Training & Continuing Professional Education',
  'Life Insurance',
  'Paid Community Volunteering Opportunities',
  'Short-Term Disability',
  'Long-Term Disability',
]

const rows = [
  ['Building Coverage', '$4,000,000', '$500,000'],
  ['Contents Coverage', '$500,000', '$500,000'],
  [
    'Additional Coverage',
    'Business Interruption, Replacement Cost on Building, Rental Loss of Income, Basement Contents, Pool Repair & Refill',
    'N/A',
  ],
  ['Elevation Certificate', 'Optional', 'No'],
  [
    'Waiting Period',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 10 days.',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 30 days.',
  ],
]

const rcbapRows = [
  [
    'Building Coverage',
    '$15,000,000 per building',
    '$250,000 per unit/per building',
  ],
  ['Contents Coverage', '$500,000', '$100,000'],
  ['Additional Coverage', 'Basement Contents, Pool Repair & Refill', 'N/A'],

  ['Elevation Certificate', 'Optional', 'No'],
  [
    'Waiting Period',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 10 days.',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 30 days.',
  ],
]

const learnMoreCards = [
  {
    headline: 'Existing Policyholders',
    body: 'Contact your agent directly, who can be found on your policy documents.',
    noIcon: true,
  },
  {
    headline: 'Existing Neptune Agents',
    body: `<p>Contact our Customer Success Team at <a href="tel:7272024815">727-202-4815</a>.</p>`,
    noIcon: true,
  },
  {
    headline: 'New Neptune Customers',
    body: `<p>Contact our team at <a href="tel:7272175343">727-217-5343</a>.</p>`,
    noIcon: true,
  },
]

const productCards = [
  {
    headline: 'MAIN STREET MERCANTILE COMMERCIAL BUILDINGS',
    body: 'An entire office, retail, restaurant, industrial, other non-residential building.',
    noIcon: true,
  },
  {
    headline: 'MIXED USE BUILDING',
    body: 'Some residential and some commercial use in the same building. Coverage for the entire building.',
    noIcon: true,
  },
  {
    headline: 'HOTELS, MOTELS, AND INNS',
    body: 'Coverage for the entire building.',
    noIcon: true,
  },
  {
    headline: 'APARTMENT OR CO-OP',
    body: 'An entire apartment building or co-op (not condominium form of ownership).',
    noIcon: true,
  },
  {
    headline: 'ENTIRE RESIDENTIAL CONDO BUILDING',
    body: 'Residential Condominium Building Association Policy – Requires condominium form of ownership.',
    noIcon: true,
  },
]

const optionalPrimaryCoverages = [
  {
    headline: 'Residential',
    body: `<div class="wpb_wrapper">
    <p><strong>Requires a minimum of $10,000 in excess contents coverage:</strong></p>
<ul>
<li>Basement Contents</li>
</ul>
  </div><div class="wpb_wrapper">
    <p><strong>Requires a minimum of $50,000 in excess building coverage:</strong></p>
<ul>
<li>Temporary Living Expenses</li>
<li>Unattached Structures</li>
<li>Pool Repair &amp; Refill</li>
<li>Loss of Rental Income</li>
</ul>
  </div>`,
    noIcon: true,
  },
  {
    headline: 'Commercial',
    body: `<div class="wpb_wrapper">
    <p><strong>Requires a minimum of $10,000 in excess contents coverage:</strong></p>
    <ul>
<li>Basement Contents</li>
</ul>
    <p><strong>Requires a minimum of $50,000 in excess building coverage:</strong></p>
<ul>
<li>Business Interruption</li>
<li>Pool Repair &amp; Refill</li>
</ul>
  </div>`,
    noIcon: true,
  },

  {
    headline: 'Apartments',
    body: `<div class="wpb_wrapper">
    <p><strong>Requires a minimum of $10,000 in excess contents coverage:</strong></p>
    <ul>
<li>Basement Contents</li>
</ul>

    <p><strong>Requires a minimum of $50,000 in excess building coverage:</strong></p>
<ul>
<li>Loss of Rental Income</li>
<li>Pool Repair &amp; Refill</li>
</ul>
  </div>`,
    noIcon: true,
  },
  {
    headline: 'RCBAP',
    body: `<div class="wpb_wrapper">
    <p><strong>Requires a minimum of $10,000 in excess contents coverage:</strong></p>
    <ul>
<li>Basement Contents</li>
</ul>

    <p><strong>Requires a minimum of $50,000 in excess building coverage:</strong></p>
<ul>
<li>Pool Repair &amp; Refill</li>
</ul>
  </div>`,
    noIcon: true,
  },
]

const cards = [
  {
    headline: 'Residential',
    body: `<table class="mt-8">
  <thead>
    <tr>
      <th>Item</th>
      <th>Maximum Coverage</th>
      <th>Minimum Coverage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Building</td>
      <td>$4,000,000</td>
      <td>$50,000</td>
    </tr>
    <tr>
      <td>Contents</td>
      <td>$500,000</td>
      <td>$10,000</td>
    </tr>
  </tbody>
</table>`,
    noIcon: true,
  },
  {
    headline: 'Commercial',
    body: `<table class="mt-8">
  <thead>
    <tr>
      <th>Item</th>
      <th>Maximum Coverage</th>
      <th>Minimum Coverage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Building</td>
      <td>$4,000,000</td>
      <td>$50,000</td>
    </tr>
    <tr>
      <td>Contents</td>
      <td>$1,000,000</td>
      <td>$10,000</td>
    </tr>
  </tbody>
</table>`,
    noIcon: true,
  },
  {
    headline: 'Apartments',
    body: `<table class="mt-8">
  <thead>
    <tr>
      <th>Item</th>
      <th>Maximum Coverage</th>
      <th>Minimum Coverage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Building</td>
      <td>$10,000,000 (per unit minimums apply)</td>
      <td>$50,000</td>
    </tr>
    <tr>
      <td>Contents</td>
      <td>$500,000</td>
      <td>$10,000</td>
    </tr>
  </tbody>
</table>`,
    noIcon: true,
  },
  {
    headline: 'RCBAP',
    body: `<table class="mt-8">
  <thead>
    <tr>
      <th>Item</th>
      <th>Maximum Coverage</th>
      <th>Minimum Coverage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Building</td>
      <td>$15,000,000</td>
      <td>$50,000</td>
    </tr>
    <tr>
      <td>Contents</td>
      <td>$500,000</td>
      <td>$10,000</td>
    </tr>
  </tbody>
</table>`,
    noIcon: true,
  },
]

const advantages = [
  'You can find your price in minutes rather than hours spent speaking with an agent for NFIP.',
  `Neptune’s waiting period is only ten days. There's a 30-day waiting period at NFIP. That means you are covered almost three weeks sooner!`,
  `We offer optional Business Interruption coverage to provide cash flow if your business is closed due to a flood.`,
  `We can insure your commercial building up to $4,000,000 and $10,000,000 for RCBAP. Meanwhile, the NFIP’s maximum is $500,000 on commercial buildings.`,
]
</script>

<style>
.swiper-wrapper {
  align-items: stretch !important;
}
</style>
