<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[900px] opacity-[0.10] z-10 mix-blend-difference"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div
      class="absolute inset-0 bg-brand-blue rounded-b-[50px] scale-y-0 origin-top"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.4,
              opacity: 1,
              scaleY: 1,
              ease: 'expo',
            })
          }
        }
      "
    ></div>
    <div class="sm:text-center">
      <h2
        v-if="marketingName"
        class="text-[24px] font-wide font-extrabold tracking-[2px] max-w-[1000px] leading-snug mb-5 opacity-0 translate-y-8 uppercase text-white sm:mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        {{ marketingName }}
      </h2>
      <h2
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[520px] md:max-w-[900px] leading-snug mb-5 opacity-0 translate-y-8 uppercase text-white sm:mx-auto"
        data-test="newAgent.signUpTitle"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        Become a Neptune Agent
      </h2>
      <p
        class="sm:max-w-[718px] sm:mx-auto text-white opacity-0 translate-y-8"
        data-test="newAgent.signUpSubtitle"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Join Neptune's team of agents and leverage our revolutionary technology
        that allows you to protect your E&O, increase your flood conversions,
        and increase retention.
      </p>
    </div>
  </section>
  <section class="mx-auto px-6 mb-24 w-full max-w-screen-xl relative z-20">
    <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
      <BenefitCard
        v-for="(card, i) in cards"
        :data="{ ...card, i }"
        :agent="true"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1 + 0.03 * i,
                ease: 'expo',
              })
            }
          }
        "
        class="translate-y-8 !opacity-[1] !cursor-default"
      ></BenefitCard>
    </div>
  </section>
  <section class="mx-auto mb-6 max-w-screen-xl px-6 relative z-30">
    <div class="mt-12">
      <AgentForm
        v-if="isReady"
        :baseUrl="data.baseUrl"
        :psdnUrl="data.psdnUrl"
      />
    </div>
  </section>
  <section class="px-6">
    <div
      class="bg-[#E0EAF3] w-full mx-auto max-w-7xl rounded-2xl grid overflow-hidden mb-5 lg:grid-cols-2 px-10 py-12"
    >
      <h2
        class="text-[24px] md:text-[30px] font-wide font-extrabold leading-snug tracking-[2px] opacity-0 uppercase text-brand-blue"
        data-test="newAgent.learnMore"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        Learn More about Neptune's Products
      </h2>
      <div
        class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-4 lg:gap-2 mt-4 lg:mt-0"
      >
        <div>
          <a
            class="block bg-brand-blue text-white no-underline uppercase font-wide tracking-[1px] px-4 !py-3.5 text-center font-bold text-xs rounded-full transition hover:bg-brand-blue/75"
            data-test="newAgent.commercial"
            target="_blank"
            href="https://neptuneprod.blob.core.windows.net/public/HostedAgentFlyersAndResources/Commercial%20Agent%20Flyer.pdf"
          >
            Commercial Flood
          </a>
        </div>
        <div>
          <a
            class="block bg-brand-blue text-white no-underline uppercase font-wide tracking-[1px] px-4 !py-3.5 text-center font-bold text-xs rounded-full transition hover:bg-brand-blue/75"
            data-test="newAgent.residential"
            target="_blank"
            href="https://neptuneprod.blob.core.windows.net/public/HostedAgentFlyersAndResources/Residential%20Agent%20Flyer.pdf"
          >
            Residential Flood
          </a>
        </div>
        <div>
          <a
            class="block bg-brand-blue text-white no-underline uppercase font-wide tracking-[1px] px-4 !py-3.5 text-center font-bold text-xs rounded-full transition hover:bg-brand-blue/75"
            data-test="newAgent.rcbap"
            href="https://neptuneprod.blob.core.windows.net/public/HostedAgentFlyersAndResources/RCBAP%20Agent%20Flyer.pdf"
            target="_blank"
          >
            RCBAP Flood
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="px-6" data-scroll-section>
    <div
      class="bg-black w-full mx-auto max-w-7xl rounded-2xl flex flex-col relative overflow-hidden p-8 md:p-20"
    >
      <img
        src="@/assets/face-white.svg"
        alt="Neptune Face"
        class="absolute top-[10px] right-[-130px] opacity-10 md:opacity-15"
        data-scroll
        data-scroll-speed="0.08"
      />
      <h1
        class="text-white max-w-[825px] text-left text-[20px] lg:text-[32px] font-wide font-extrabold tracking-[2px] uppercase opacity-0 translate-y-8 mb-4 md:mb-0"
        data-test="newAgent.questions"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.2,
                ease: 'expo',
              })
            }
          }
        "
      >
        For Questions, Please reach out to one of our team members
      </h1>
      <span
        class="border-2 border-brand-teal w-[100px] opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.2,
                ease: 'expo',
              })
            }
          }
        "
      ></span>
      <div class="text-white leading-10 mt-5">
        <p
          data-test="newAgent.personalQuestions"
          class="opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.2,
                  ease: 'expo',
                })
              }
            }
          "
        >
          • For Personal Lines: Gabby
          <a href="tel:7272315255" class="hover:underline">(727) 231-5255</a> or
          <a href="mailto:gabby@neptuneflood.com" class="hover:underline"
            >gabby@neptuneflood.com</a
          >
        </p>
        <p
          data-test="newAgent.commercialQuestions"
          class="opacity-0 translate-y-8 leading-snug"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.2,
                  ease: 'expo',
                })
              }
            }
          "
        >
          • For Commercial Lines: Jake
          <a href="tel:6177554405" class="hover:underline">(617) 755-4405</a> or
          <a href="mailto:jake@neptuneflood.com" class="hover:underline"
            >jake@neptuneflood.com
          </a>
        </p>
        <p
          data-test="newAgent.onboardingQuestions"
          class="opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.2,
                  ease: 'expo',
                })
              }
            }
          "
        >
          • For Onboarding Questions: Debbie at
          <a href="mailto:support@neptuneflood.com" class="hover:underline"
            >support@neptuneflood.com</a
          >
        </p>
      </div>
    </div>
  </section>
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import BenefitCard from '@/components/BenefitCard.vue'
import AgentForm from '../components/AgentForm/AgentForm.vue'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'

const isReady = ref(false)
const store = useStore()
const { data } = defineProps(['data'])
import LocomotiveScroll from 'locomotive-scroll'

const marketingName = computed(() => {
  return store?.state?.subagency?.agencyData?.parentMarketingName
})

onMounted(async () => {
  const scroll = new LocomotiveScroll()
  console.log(scroll)

  try {
    await store.dispatch('auth/getToken')
    const token = store.state.auth.token // Access the token from the store's state
    if (window.location.hash.includes('sub-agent')) {
      let link = window.location.hash.split('/sub-agent/')[1]
      await store.dispatch('subagency/getSubAgencyData', {
        token,
        link: link,
      })
      isReady.value = true // Set the isReady flag after dispatch action completes
      return
    }
  } catch (e) {
    console.error(e);
    store.dispatch('subagency/setSubAgencyDataError', e);
  } finally {
    isReady.value = true // Set the isReady flag if no sub-agent route
  }
})

const cards = [
  {
    headline: 'Higher Limits',
    link: {
      text: 'Up to $4,000,000 in Property Coverage for Residential and Commercial and up to $10,000,000 for RCBAP',
    },
    icon: 'Dollar',
  },
  {
    headline: 'Broad Availability',
    link: {
      text: 'Residential, Commercial and RCBAP available nationwide except Alaska',
    },
    icon: 'Building',
  },
  {
    headline: 'Shorter Waiting Period',
    link: {
      text: 'Only a 10-day waiting period, with no waiting period for property closings or coverage rollover',
    },
    icon: 'Calendar',
  },
  {
    headline: 'Increased Coverage',
    link: { text: 'Up to $500,000 in Contents Coverage' },
    icon: 'Dollar',
  },
  {
    headline: 'Faster Quoting',
    link: { text: 'Quote-to-bind process in under two minutes' },
    icon: 'Clock',
  },
  {
    headline: 'Less Documentation',
    link: { text: 'No elevation certificate required' },
    icon: 'Document',
  },
]
</script>
