export const generalFaqs = [
  {
    question:
      'HOW DOES A NEPTUNE FLOOD INSURANCE POLICY DIFFER FROM AN NFIP POLICY?',
    answer:
      'Neptune offers private flood insurance, providing higher and broader coverage options than those available through the National Flood Insurance Program (NFIP). The NFIP is a government-backed program through the Federal Emergency Management Agency (FEMA) and is limited in the amount of coverage it can offer. For example, an NFIP policy does not provide optional coverages like Temporary Living Expenses or Pool Repair and Refill.',
    open: false,
  },
  {
    question: `Does a Neptune Primary Flood Policy policy meet the private flood requirements?`,
    answer: `<p>This policy meets the definition of private flood insurance contained in 42 U.S.C. 4012a(b)(7) and the corresponding regulation and does not have any provision which is not in compliance with 42 U.S.C. s. 4012a(b).</p><p>Confirm with your lending institution if a private flood policy will satisfy your loan requirements.</p>`,
    open: false,
  },
  {
    question: 'WHAT COVERAGE LIMITS ARE AVAILABLE WITH NEPTUNE?',
    answer: `Neptune offers building coverage up to $4,000,000 on residential, $4,000,000 on commercial, and $10,000,000 on RCBAP. Neptune offers contents coverage up to $500,000 on residential, $1,000,000 on commercial, and $500,000 on RCBAP. In addition, Neptune offers a suite of additional coverages to allow you to comprehensively protect your property.`,
    open: false,
  },
  {
    question: 'WHAT BROADER COVERAGE OPTIONS ARE AVAILABLE WITH NEPTUNE?',
    answer: `<p>Neptune provides a variety of options so each homeowner can customize coverage to protect their assets</p>
    <ul>
      <li>Residential: Basement Contents, Temporary Living Expenses, Unattached Structures, Pool Repair & Refill, Loss of Rental Income, Replacement Cost on Contents, Building Betterments & Improvements</li>
      <li>Commercial: Business Interruption, Pool Repair & Refill, Basement Contents, Replacement Cost on Building, Loss of Rental Income (Apartments Only), Building Betterments & Improvements</li>
      <li>RCBAP: Pool Repair & Refill, Basement Contents</li>
    </ul>
    `,
    open: false,
  },
  {
    question: `IS THE WAITING PERIOD THE SAME AS THE NFIP?`,
    answer: `No. Neptune provides a reduced waiting period of only 10 days, compared to 30 days under the NFIP. Neptune has no waiting period if coverage is for a real estate closing or a rollover from an existing flood policy.`,
    open: false,
  },

  {
    question: 'WHAT IS MY DEDUCTIBLE AND HOW IS IT APPLIED?',
    answer:
      'At Neptune, we offer various deductible options. Your deductible is the amount of insured loss that is your responsibility and that is incurred by you before any amounts are paid for the insured loss under your flood insurance policy. The deductible amount you select is shown on your declarations page. In each flood loss, the deductible amount applies separately to Building Coverage and Contents Coverage.',
    open: false,
  },

  {
    question: 'KEY DEFINITIONS IN A NEPTUNE RESIDENTIAL POLICY',
    answer: `
  <p>The following are key definitions that we use in our policies. For the most updated information, <a href="/support">click here</a> to visit our Support page and see sample policy documents. You should always reference your official policy documents for full details.</p>
  <ol>
    <li><strong>Actual Cash Value</strong> - The cost to replace an insured item of property at the time of loss, less the value of its physical depreciation.</li>
    <li><strong>Basement</strong> - Any area of the Building, including any sunken room or sunken portion of a room, having its floor below ground level (subgrade) on all sides.</li>
    <li><strong>Building</strong> -
        <ol>
            <li>A structure with two or more outside rigid walls and a fully secured roof, which is affixed to a permanent site;</li>
            <li>A manufactured home, also known as a mobile home, is a structure: built on a permanent chassis, transported to its site in one or more sections, and affixed to a permanent foundation; or</li>
            <li>A travel trailer without wheels, built on a chassis and affixed to a permanent foundation, that is regulated under the community’s floodplain management and Building ordinances or laws.</li>
        </ol>
        “Building” does not mean a gas or liquid storage tank or a recreational vehicle, park trailer, or other similar vehicle, except as described above.
    </li>
    <li><strong>Cancellation</strong> - The ending of the insurance coverage provided by the policy before the expiration date.</li>
    <li><strong>Declarations page</strong> - A summary of information you provided in the application for insurance. The declarations page also describes the terms of the policy, limits of coverage, and displays the premium and our name. The declarations page is a part of the flood insurance policy.</li>
    <li><strong>Described Location</strong> - The location where the insured building(s) or personal property is found. The described location is shown on the declarations page.</li>
    <li><strong>Direct Physical Loss by or from Flood</strong> - Loss or damage to insured property, directly caused by a flood. There must be evidence of physical changes to the property.</li>
    <li><strong>Dwelling</strong> - A building designed for use as a residence for no more than four families or a single-family unit in a condominium building.</li>
    <li><strong>Elevated Building</strong> - A building that has no basement and that has its lowest elevated floor raised above ground level by foundation walls, shear walls, posts, piers, pilings, or columns.</li>
    <li><strong>Improvements</strong> - Fixtures, alterations, installations, or additions comprising a part of the “Dwelling” or apartment in which you reside.</li>
    <li><strong>Mudflow</strong> - A river of liquid and flowing mud on the surfaces of normally dry land areas, as when earth is carried by a current of water. Other earth movements, such as landslide, slope failure, or a saturated soil mass moving by liquidity down a slope, are not mudflows.</li>
    <li><strong>Policy</strong> - The entire written contract between you and us. It includes:
        <ol>
            <li>The printed form;</li>
            <li>The application and declarations page;</li>
            <li>Any endorsement(s) that may be issued; and</li>
            <li>Any renewal certificate indicating that coverage has been instituted for a new policy and new policy term. Only one dwelling, which you specifically describe in the application, may be insured under the policy.</li>
        </ol>
    </li>
</ul>


  `,
    open: false,
  },
  {
    question: 'WHAT IS THE AVERAGE PAID FLOOD INSURANCE CLAIM?',
    answer: 'Over the last 10 years, the average flood claim is over $55k.',
    open: false,
  },
  {
    question: 'WHAT IS THE AVERAGE FLOOD INSURANCE PREMIUM?',
    answer:
      'The average cost of a residential flood insurance policy is $1,032 per year. But, because rates are based on many variables, your premium may be far less if your property is at low risk of flood damage.',
    open: false,
  },
]

export const floodInsurance101FAQs = [
  {
    question: 'Doesn’t my Homeowners Insurance policy cover flooding?',
    answer:
      'The coverage offered in a Flood policy may sound like coverage you already have within your Homeowners policy, but that is not the case.  Flood coverage is different than your Homeowners insurance because most Homeowners policies specifically exclude water damage that results from rising water.  For insurance lingo, “rising” water distinguishes flood damage from water damage.',
    open: false,
  },

  {
    question: 'I don’t live near water. How do I know if I need insurance?',
    answer:
      '<p>If your property is identified as <a href="https://help.riskfactor.com/hc/en-us/articles/360048256493-Understand-the-differences-between-FEMA-flood-zones">“high risk” (A and V flood zones)</a>, most lenders require you to have flood insurance. &nbsp;Regardless, no one is without potential flood risk. &nbsp;Wherever it rains, it can flood. &nbsp;Since 25% of all flood claims are actually located outside of “high risk” A and V zones, only you can answer this question for your family. &nbsp;You might consider the cost of insurance vs. the average paid claim to decide if you can afford to cover your own loss.</p>',
    open: false,
  },
  {
    question: 'What is the average paid flood claim?',
    answer:
      '<p>During the last five years, the average flood claim was <a href="https://neptuneflood.com/flood-insurance-coverage/">$46,000</a>.</p>',
    open: false,
  },
  {
    question: 'What is the average flood premium?',
    answer:
      'The average cost of flood insurance is $700 per year.  But, because rates are based on many variables, including flood zone, coverage amounts and the elevation of your home, your premium will likely be far less if you are in a moderate to low risk zone.  A or V zones (high risk) are wide-ranging, but they average around $1,500 per year.',
    open: false,
  },
  {
    question: 'What types of events are considered “flood” events?',
    answer: `<div class="wpb_wrapper">
    <p>There are four primary causes of floods:</p>
<ol>
<li>River or coastal flooding, such as spring runoff, raising river levels or hurricane driven rains and tidal surges.</li>
<li>Rapid runoff or buildup of water, such as torrential rains in low lying areas.</li>
<li>Mudflow in inundated, normally dry areas.</li>
<li>Collapse of water retention structures such as levees or dams, or erosion of coastal or lakeside seawalls and shoreline. Flooding can occur anywhere, whether along the shore, inland or at altitude. &nbsp;Major events occur in every state in the US.</li>
</ol>
  </div>`,
    open: false,
  },
  {
    question:
      'Won’t the Federal Government just bail me out if I don’t have flood insurance?',
    answer:
      '<p>If your home is within a declared disaster area, you may apply to borrow up to $200,000 to repair or replace your primary home to its pre-disaster condition. &nbsp;It’s actually a loan that must be paid back with interest and not always available. &nbsp;You can’t use the loan to upgrade or add on to the home, unless required by building authority or code. &nbsp;Second homes or vacation properties are not eligible to apply for this federal loan program.</p>',
    open: false,
  },
]

export const buyingFloodInsuranceFAQs = [
  {
    question: 'What does a Flood policy cover?',
    answer: `<div class="wpb_wrapper">
    <p>The basic coverage options are for your building and/or your personal property, described in more detail below:</p>
<p>The main dwelling building is typically paid out on a Replacement cost basis (what it would cost to rebuild the home).</p>
<p>Coverage A – Dwelling&nbsp;Property Coverage has a maximum limit of $250,000<br>
What this typically provides coverage for:</p>
<ul>
<li>the building and its foundation</li>
<li>plumbing and electrical systems</li>
<li>central air and heating systems</li>
<li>attached bookcases, cabinets and paneling</li>
</ul>
<p>Detached Garage – this coverage is limited to no more than 10 percent of the limit of liability on the “Dwelling”. This is typically paid out at Actual Cash Value (which is replacement cost minus depreciation).</p>
<p>Other detached structures will require a separate policy.</p>
<p>Coverage B – Personal Property Coverage has a maximum limit of $100,000<br>
What this typically provides coverage for:</p>
<ul>
<li>Clothing, furniture and electronics; curtains</li>
<li>Some portable appliances; freezers and the foods within them</li>
<li>Certain valuables like art (subject to a special limit)</li>
</ul>
<p>Typically paid out on an Actual Cash Value basis</p>
  </div>`,
    open: false,
  },

  {
    question: 'What’s not typically covered by Flood insurance?',
    answer: `<div class="wpb_wrapper">
    <p>Listed below are a few types of property and expenses that generally fall outside the scope of a basic flood policy. &nbsp;Neptune can help cover many of the items shown, if you select optional coverages.</p>
<ol>
<li>Moisture or mold/mildew damage that “could have been avoided by the homeowner” (the lack of timely remediation).</li>
<li>Currency and precious metals, and paper valuables like stock certificates.</li>
<li>Outdoor property like decks, fences, patios, landscaping, wells and septic systems, and hot tubs and pools.</li>
<li>Living expenses, like temporary housing (if flood damage makes your home uninhabitable).</li>
<li>Cars and other self-propelled vehicles (but your auto insurance may offer some coverage for your car).</li>
<li>Items in below-ground rooms like crawl spaces and basements. &nbsp;Some items in these spaces (like the furnace) are typically covered under building coverage. &nbsp;Others (like the washer/dryer) are usually covered under personal contents coverage. &nbsp;And some items may not be covered at all when they’re kept in below-ground rooms.</li>
</ol>
<p>Excess insurance is available through some insurers, and it might be a good option if you’re interested in increasing the limits available from a Neptune Flood policy. Read more flood insurance FAQ questions above.</p>
  </div>`,
    open: false,
  },
]

export const residentialFaqs = [
  {
    question: 'WHAT IS PRIVATE FLOOD INSURANCE?',
    answer: `<p>
      The majority of flood coverage in the United States, including both
      residential flood insurance and coverage for businesses, has historically
      been provided by the government through the National Flood Insurance
      Program (NFIP). Established in 1968, the NFIP offers flood coverage to
      homeowners, renters, and business owners if their community participates
      in the program. Until 2012, banks and mortgage companies were limited to
      accepting only an NFIP policy as valid flood coverage.
    </p>
    <p>
      However, the landscape of flood insurance in the United States underwent a
      significant transformation with the passing of the Biggert-Waters Flood
      Insurance Reform Act in 2012. This pivotal legislation opened the doors
      for private flood insurance companies to enter the market, offering
      alternatives to the government's program. This development not only
      expanded coverage options for consumers but also introduced competitive
      rates that could lead to cost savings.
    </p>
    <p>
      A Neptune policy is at the forefront of this change. Neptune provides a
      viable alternative that meets the requirements set by banks and mortgage
      companies. As a result, all major institutions accept Neptune coverage
      instead of the NFIP. Thanks to our advanced technology, Neptune offers
      enhanced residential flood insurance coverage that could save you up to
      25% for the same or better coverage compared to an NFIP policy. This
      breakthrough in private flood insurance represents a significant
      advancement in protecting properties from flood damage, ensuring
      homeowners and business owners have access to comprehensive and
      cost-effective flood insurance options.
    </p>
`,
  },
  {
    question: 'WHEN SHOULD I BUY RESIDENTIAL FLOOD INSURANCE',
    answer: `<p>You should consider buying flood insurance as soon as possible, especially if you live in an area prone to flooding or if your mortgage lender requires it. It's essential to have coverage before a flood occurs, as most policies have a waiting period before they become effective. Neptune has a 10-day waiting period, with immediate availability for real estate closings or rollovers from another flood policy. Don't wait until it's too late – buy flood insurance today to protect your home and belongings from the devastating effects of floods.
    </p>
`,
  },
  {
    question: 'Is Neptune residential flood insurance bank-approved?',
    answer: `<p>Yes, a Neptune policy meets the definition of private flood insurance contained in 42 U.S.C. 4012a(b)(7) and the corresponding regulation and does not have any provision which is not in compliance with 42 U.S.C. s. 4012a(b).</p>`,
    open: false,
  },
  {
    question: `Should I buy flood insurance if I don't live near or in a flood zone?`,
    answer:
      'Floods are the most frequent natural disaster in the United States, posing a greater risk to homes than fire incidents. These events result from various factors such as storms, melting snow, hurricanes, and water backup due to drainage system issues or failures in dams or levees.',
    open: false,
  },
  {
    question: 'Can I buy flood insurance as a renter?',
    answer:
      'Yes, renters can buy flood insurance. Flood insurance isn’t just for homeowners and businesses.',
    open: false,
  },
  {
    question:
      'I have an NFIP policy. Should I still get private flood insurance?',
    answer:
      'Private flood insurance may be a good option if you need more coverage options than the National Flood Insurance Program (NFIP), need higher limits of coverage, or are looking for savings. Neptune also offers excess flood insurance that allows you to purchase coverages on top of your existing NFIP policy.',
    open: false,
  },

  {
    question:
      'What are the differences between private and government-backed residential flood insurance?',
    answer: `Private flood insurance is written and funded by private insurance companies, while the government-backed National Flood Insurance Program (NFIP) is administered by the Federal Emergency Management Agency (FEMA). Private flood insurance is often more flexible and offers broader coverage options than FEMA's National Flood Insurance Program (NFIP).`,
    open: false,
  },
]

export const excessFaqs = [
  {
    question:
      'What is the difference between a primary flood insurance policy and an excess flood insurance policy?',
    answer: `<div class="wpb_wrapper">
    <p>In the event of a loss, a primary flood insurance policy is your first line of defense. The primary policy will cover approved flood-related expenses up to your purchased coverage limit.</p>
<p>If the total loss value in your claim exceeds the maximum available from the NFIP, the next line of defense is your excess flood policy. The excess policy will cover flood-related expenses above your primary policy, up to its coverage limit.</p>
  </div>`,
    open: false,
  },
  {
    question:
      'Can I purchase an excess policy on top of my Neptune flood insurance policy?',
    answer:
      'No. A Neptune excess flood policy is only available on top of a National Flood Insurance Program (NFIP) policy.',
    open: false,
  },
  {
    question:
      'I purchased below the NFIP’s $250,000 maximum building coverage limit on a home. Can I still purchase an excess flood policy?',
    answer:
      'Yes. You can still purchase an excess flood policy, but the excess coverage does not kick in until the loss value is above $250,000, regardless of whether you have the full NFIP coverage value or not. The answer is the same for businesses where the available limit from the NFIP is $500,000, and the excess policy kicks in above that level.',
    open: false,
  },
  {
    question:
      'What is required to add optional primary coverages to my policy?',
    answer:
      'The optional coverages require a minimum excess building and/or contents coverage limit before being available. View the chart above to see the limits for each optional coverage.',
    open: false,
  },
  {
    question:
      'For apartment buildings, what is the minimum required building coverage needed?',
    answer: `<div class="wpb_wrapper">
    <p>The minimum coverage required for a unit-based commercial product is based on the number of units.</p>
<ul>
<li>1-10 units – a minimum of $50,000 in building coverage</li>
<li>11-20 units – $50,000 total for units 1-10, and an additional $50,000 per unit above 10 units, up to 20 units.</li>
<li>21+ units – $500,000 minimum in building coverage</li>
</ul>
  </div>`,
    open: false,
  },
  {
    question: 'What states are the excess flood policy available in?',
    answer:
      'Neptune’s excess flood insurance is available in 49 states and Washington, D.C. Like our primary flood insurance, it is not available in Alaska.',
    open: false,
  },
  {
    question:
      'Can an excess flood policy be paid through my mortgage or an escrow account?',
    answer:
      'No. An excess policy must be paid in full at the time of purchase and cannot be billed to your mortgage company.',
    open: false,
  },
  {
    question: 'Can I cancel my excess flood policy at any time?',
    answer:
      'Yes, you can cancel at any time. However, there is a minimum earned premium of 25%. Policy fees are non-refundable once a policy has gone into effect.',
    open: false,
  },
]

export const commercialFaqs = [
  {
    question:
      'How does a Neptune Flood Insurance Policy differ from an NFIP policy?',
    answer:
      'A Neptune flood policy provides the same base flood coverage as the NFIP. Our greater limits and broader coverage options provide additional coverage not available through the NFIP.',
    open: false,
  },
  {
    question: 'Is the waiting period with Neptune the same as the NFIP?',
    answer:
      'No.  Neptune provides a reduced waiting period of only ten days compared to the 30-day waiting period under the NFIP. There is no waiting period when a Neptune policy is purchased for a loan closing or rolling over from an existing flood policy.',
    open: false,
  },
  {
    question:
      'How do I check if my business is located in or near a flood zone?',
    answer:
      'You can use the Federal Emergency Management Agency (FEMA) Flood Map Service Center to get flood risk information by entering your address.',
    open: false,
  },
  {
    question: 'What Does Commercial Flood Insurance Cover?',
    answer:
      'Commercial flood insurance covers structures used for business purposes, like company-owned buildings, and detached commercial structures, like garages. Each building typically requires its own policy. It may also cover equipment, inventory, and machinery associated with the business operations. You can review a sample commercial policy on our <a href="/support"> support page</a>.',
    open: false,
  },
  {
    question: 'How much does Commercial Flood Insurance cost?',
    answer:
      'The cost will depend on the commercial property location, as some areas are at higher risk for flooding than others. In addition, factors such as deductible amounts, building age, construction type, and previous claims history may affect the overall price.',
    open: false,
  },
  {
    question: 'Does My Business Need Flood Insurance?',
    answer: `The best time to buy business flood insurance is before you urgently need
      it. Taking action early can protect your business from unforeseen
      financial strain due to flooding. Protecting your business now is crucial
      given the unpredictable nature of weather patterns and the increasing
      occurrence of unexpected flooding. Being proactive in securing flood
      insurance ensures that your business is safeguarded when you most need it.`,
    open: false,
  },
]
