<template>
  <div
    class="mx-auto !max-w-6xl mt-12 opacity-0 translate-y-8"
    v-intersection-observer="
      ([{ isIntersecting, target }]) => {
        if (isIntersecting) {
          gsap.to(target, {
            duration: 1.0,
            opacity: 1,
            delay: 0.2,
            y: 0,
            ease: 'expo',
          })
        }
      }
    "
  >
    <table class="">
      <thead>
        <tr>
          <th class="w-[31%] py-6 text-left">Commercial Comparison</th>
          <th class="w-[30.5%] text-center pb-0">
            <span
              class="bg-brand-blue block px-8 py-6 rounded-t-3xl shadow-2xl shadow-brand-blue"
            >
              <span class="relative top-[4px]">
                <span
                  class="block text-[24px] font-extrabold tracking-[1px] leading-none text-white text-xs lg:text-base"
                  >NEPTUNE</span
                >
                <span class="text-xs lg:text-base block font-normal text-white"
                  >Commercial Flood</span
                >
              </span>
            </span>
          </th>
          <th class="w-1/3 text-center px-8 py-6 text-[#53575A]">
            <span class="leading-none text-xs lg:text-base">NFIP</span>
            <span class="block font-normal leading-none">Commercial Flood</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rows">
          <td class="text-xs lg:text-base">{{ row[0] }}</td>
          <td class="text-center py-0 text-xs lg:text-base">
            <span
              class="bg-brand-blue text-white block px-8 py-6 shadow-2xl shadow-brand-blue"
              :class="{ 'rounded-b-3xl': i === rows.length - 1 }"
              v-html="row[1]"
            >
            </span>
          </td>
          <td
            class="text-center px-8 py-6 text-[#53575A] text-xs lg:text-base"
            v-html="row[2]"
          ></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
const rows = [
  ['Building Coverage', '$4,000,000', '$500,000'],
  ['Apartments', '$10,000,000<br />per building', '$500,000'],
  [
    'Contents Coverage',
    '$1,000,000<br/> <div class="opacity-0 pointer-events-none">hidden</div> <div class="opacity-0 pointer-events-none lg:hidden">hidden</div>  <div class="opacity-0 pointer-events-none lg:hidden">hidden</div>',
    '$1,000,000 Commercial<br />$500,000 Apartments',
  ],
  [
    'Additional Coverage',
    'Business Interruption, Replacement Cost on Building, Rental Loss of Income, Basement Contents, Pool Repair & Refill, Building Betterments & Improvements',
    'N/A',
  ],
  ['Elevation Certificate', 'Optional', 'Optional'],
  [
    'Waiting Period',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 10 days.',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 30 days.',
  ],
]
</script>
