import { createStore, createLogger } from "vuex";
import auth from "./modules/auth";
import subagency from "./modules/subagency";
import verification from "./modules/verification.js";

const debug = process.env.NODE_ENV !== "production";

export const StoreConfiguration = {
  modules: {
    auth,
    subagency,
    verification,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
};

export default createStore(StoreConfiguration);
