import Axios from 'axios'

// initial state
const state = () => ({
  isSubAgent: false,
  logo: null,
})

const getters = {
  isSubAgent(state) {
    return state.isSubAgent
  },
  logo(state) {
    return state.logo
  },
  getSubAgencyDataError(state) {
    return state.error
  },
}

// actions
const actions = {
  async getSubAgencyData({ commit }, { token, link }) {
    if (!link) {
      return
    }

    const res = await Axios.get(
      `${phpVars.psdnUrl}/api/v1/agenciesauto/linkdata/?link=${link}`,
    )

    if (!res.data || !res.data.payload) {
      return
    }

    let subsArr = res.data.payload.subs

    if (res.data.payload.subs) {
      subsArr.sort((a, b) => {
        // Compare the city values
        const cityA = a.city.toUpperCase()
        const cityB = b.city.toUpperCase()

        if (cityA < cityB) {
          return -1 // a should come before b
        } else if (cityA > cityB) {
          return 1 // a should come after b
        }

        // If the city values are equal, compare the agencyNo values
        const agencyNoA = a.agencyNo
        const agencyNoB = b.agencyNo

        if (agencyNoA < agencyNoB) {
          return -1 // a should come before b
        } else if (agencyNoA > agencyNoB) {
          return 1 // a should come after b
        }

        return 0 // a and b are equal
      })
    }

    commit('setGrandSubs', subsArr)
    commit('setIsSubAgent', true)
    commit('setLogo', res.data.payload.logo)
    commit('setAgencyData', res.data.payload)
    const agencyDetails = await Axios.get(
      `${phpVars.psdnUrl}/api/v1/agencies/AgencyDetails/${res.data.payload.parentAgencyNo}`,
      { headers: { Authorization: token } },
    )
    commit('setAgencyDetails', agencyDetails.data.payload)
    return
  },
  setSubAgencyDataError({ commit }, error) {
    commit("SET_ERROR", error);
  }
}

// mutations
const mutations = {
  setIsSubAgent(state, isSubAgent) {
    state.isSubAgent = isSubAgent
  },
  setLogo(state, logo) {
    state.logo = logo
  },
  setAgencyData(state, data) {
    state.agencyData = data
  },
  setGrandSubs(state, data) {
    state.grandkidSubs = data
  },
  setAgencyDetails(state, data) {
    state.agencyDetailsObj = data
  },
  SET_ERROR(state, data) {
    state.error = data;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
