import { computed } from 'vue'

export function useUTMLink(baseURL, options = {}) {
  // Provide a default or allow passing the check function as an option
  const isBlogPage =
    options.isBlogPage ||
    (() => {
      const path = window.location.pathname
      return path.startsWith('/blog')
    })

  const appendUTMParams = (url) => {
    const currentUrlParams = new URLSearchParams(window.location.search)
    const allParams = [
      'utm_source',
      'utm_medium',
      'utm_term',
      'utm_content',
      'utm_campaign',
      'source',
      'payload',
      'gclid',
    ]
    const urlParts = url.split('?')
    const baseUrlPart = urlParts[0]
    const existingParams =
      urlParts.length > 1
        ? new URLSearchParams(urlParts[1])
        : new URLSearchParams()

    allParams.forEach((param) => {
      if (currentUrlParams.has(param) && !existingParams.has(param)) {
        existingParams.set(param, currentUrlParams.get(param))
      }
    })

    // Add payload=blog parameter if it's a blog page and not already set
    if (isBlogPage() && !existingParams.has('payload')) {
      existingParams.set('payload', 'blog')
    }

    return `${baseUrlPart}?${existingParams.toString()}`
  }

  const linkUrl = computed(() => (baseURL ? appendUTMParams(baseURL) : '#'))
  return { linkUrl }
}
