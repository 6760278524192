<template>
  <table class="prose !max-w-none">
    <thead>
      <tr>
        <th class="w-[31%] py-6 text-left text-sm">Residential Comparison</th>
        <th class="w-[37.5%] text-center pb-0">
          <span
            class="bg-brand-blue block px-8 py-6 rounded-t-3xl shadow-2xl shadow-brand-blue"
          >
            <span class="relative top-[4px]">
              <span
                class="block text-sm lg:text-[24px] font-extrabold tracking-[1px] leading-none text-white"
                >NEPTUNE</span
              >
              <span class="text-xs lg:text-base block font-normal text-white"
                >Residential Flood</span
              >
            </span>
          </span>
        </th>
        <th class="w-1/3 text-center px-8 py-6 text-[#53575A]">
          <span class="leading-none text-sm lg:text-base">NFIP</span>
          <span class="block font-normal leading-none text-xs lg:text-base"
            >Residential Flood</span
          >
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, i) in rows">
        <td class="text-xs lg:text-base">{{ row[0] }}</td>
        <td class="text-center py-0">
          <span
            class="bg-brand-blue text-white block px-8 py-6 shadow-2xl shadow-brand-blue text-xs lg:text-base"
            :class="{ 'rounded-b-3xl': i === rows.length - 1 }"
          >
            {{ row[1] }}
          </span>
        </td>
        <td
          class="text-center px-8 py-6 text-[#53575A] text-xs lg:text-base relative"
        >
          <div class="absolute inset-0 grid items-center justify-center p-8">
            {{ row[2] }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'

const rows = [
  ['Property Coverage', '$4,000,000', '$250,000'],
  ['Contents Coverage', '$500,000', '$100,000'],
  [
    'Additional Coverage',
    'Temporary Living Expenses, Replacement Cost on Contents, Basement Contents, Unattached Structures, Pool Repair & Refill, Loss of Rental Income, Building Betterments & Improvements',
    'N/A',
  ],
  ['Elevation Certificate', 'Optional', 'Optional'],
  [
    'Waiting Period',
    'No wait on real estate closings or rollovers from another flood policy. Otherwise, 10 days.',
    'No wait on real estate closings or rollovers from another flood policy. Otherwise, 30 days.',
  ],
]
</script>
