<template>
  <div :class="[containerClass]">
    <label
      :for="filteredAttrs['id']"
      class="block text-sm font-medium text-gray-700"
      >{{ labelText }}</label
    >
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <span class="text-gray-500 sm:text-sm"> $ </span>
      </div>
      <input
        :id="filteredAttrs['id']"
        class="focus:ring-brand-blue focus:border-brand-blue block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        placeholder="0.00"
        v-bind="filteredAttrs"
        :value="modelValue"
        @input="onInput"
        :class="{ '!border-red-700': props?.errors?.length > 0 }"
      />
    </div>
  </div>
</template>
<script setup>
import { useAttrs } from "vue";
const { class: containerClass, ...filteredAttrs } = useAttrs();

const props = defineProps({
  labelText: String,
  modelValue: [String, Number, Date],
  errors: Array,
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>
<script>
export default {
  inheritAttrs: false,
};
</script>
