<template>
  <div class="relative">
    <input
      type="text"
      class="bg-white rounded-full px-5 text-sm sm:text-base placeholder:text-gray-500 placeholder:top-[1px] placeholder:relative w-full py-4"
      data-test="quoteForm.addressInput"
      placeholder="Enter Address, City, State, Zip"
      v-model="searchQuery"
      @input="handleInputChange"
    />
    <ul
      v-if="suggestions.length > 0 && !selectedFromSuggestions"
      class="text-left left-[10px] top-[101%] absolute top-full bg-white rounded-lg z-10 shadow-sm border border-gray-200 p-1"
      v-on-click-outside="
        () => {
          suggestions = []
        }
      "
    >
      <li
        v-for="suggestion in suggestions"
        class="hover:bg-gray-100 px-1 py-1.5 rounded-lg cursor-pointer"
        data-test="quoteForm.addressSuggestion"
        @click="selectSuggestion(suggestion)"
      >
        <span class="relative top-[1px]">
          <span
            >{{ suggestion.street_line }} {{ suggestion.city }}
            {{ suggestion.state }} {{ suggestion.zipcode }}</span
          >

          <span v-if="suggestion.entries">
            ({{ suggestion.entries }} entries)</span
          >
        </span>
      </li>
    </ul>
  </div>
  <button
    class="rounded-full bg-brand-blue pl-4 pr-2.5 absolute right-0 top-1/2 -translate-y-1/2 font-bold text-sm mr-1.5 py-[14px] text-white hover:bg-brand-blue/75 transition hidden sm:inline-block"
    data-test="quoteForm.addressSubmit"
    @click.prevent="handleSubmit"
    @submit.prevent=""
  >
    <span class="relative inline-flex space-x-1.5 uppercase">
      <span class="relative top-[1px] tracking-[1px]">Get Quote</span>
      <ArrowRightIcon class="w-5"
    /></span>
  </button>
  <div class="sm:hidden">
    <button
      class="rounded-full text-base md:text-sm px-6 md:pl-3.5 md:pr-2.5 font-bold mr-1.5 py-3 text-white md:hover:bg-brand-blue/75 transition text-center mt-4 uppercase tracking-[1px]"
      :class="props.buttonColor"
      data-test="quoteForm.addressSubmit"
      @click.prevent="handleSubmit"
      @submit.prevent=""
    >
      <span class="inline-flex space-x-1.5">
        <span class="relative top-[1px]">Get Quote</span>
        <ArrowRightIcon class="w-5"
      /></span>
    </button>
  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { ref, watch, defineProps, onMounted } from 'vue'

const searchQuery = ref('')
const suggestions = ref('')
const selectedFromSuggestions = ref(false)
const props = defineProps({
  baseUrl: {
    default: 'https://dev.neptuneflood.com',
  },
  productType: {
    default: 'residential',
  },
  buttonColor: { default: 'bg-brand-blue hover:bg-brand-blue/75' },
})

// Define the tracking parameters we want to store
const TRACKING_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
  'source',
  'payload',
  'gclid',
]

// Cookie handling functions
const setCookie = (name, value, days = 30) => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

const getCookie = (name) => {
  const cookieName = `${name}=`
  const cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    cookie = cookie.trim()
    if (cookie.startsWith(cookieName)) {
      return cookie.substring(cookieName.length)
    }
  }
  return null
}

const removeCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

// Check if current URL is a blog page
const isBlogPage = () => {
  const path = window.location.pathname
  return path.startsWith('/blog')
}

// Store URL parameters in cookies, with special handling for blog pages
const storeParamsInCookies = () => {
  const urlParams = new URLSearchParams(window.location.search)

  // On blog pages, only store params if no cookies exist
  if (isBlogPage()) {
    TRACKING_PARAMS.forEach((param) => {
      const urlValue = urlParams.get(param)
      const cookieValue = getCookie(param)

      // Only set cookie if it doesn't already exist
      if (urlValue && !cookieValue) {
        setCookie(param, urlValue)
      }
    })
    return // Exit early for blog pages
  }

  // For non-blog pages, only process if we have URL parameters
  if (urlParams.toString()) {
    TRACKING_PARAMS.forEach((param) => {
      const urlValue = urlParams.get(param)
      const cookieValue = getCookie(param)

      if (urlValue) {
        // If parameter exists in URL, set/override the cookie
        setCookie(param, urlValue)
      } else if (cookieValue) {
        // Only remove cookie if we have a URL with params but this specific param is now missing
        removeCookie(param)
      }
    })
  }
}

// Initialize on component mount
onMounted(() => {
  storeParamsInCookies()
})

const handleInputChange = () => {
  if (selectedFromSuggestions.value) {
    selectedFromSuggestions.value = false
  }
}

const selectSuggestion = (suggestion) => {
  searchQuery.value = `${suggestion.street_line} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`
  suggestions.value = []
  selectedFromSuggestions.value = true
}

const searchAddress = async (query) => {
  const params = new URLSearchParams({
    key: '19033897084078356',
    search: query,
    max_results: 10,
    prefer_geolocation: 'CITY',
    source: 'postal',
  })
  try {
    const response = await fetch(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?${params.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data.suggestions || []
  } catch (error) {
    console.error(error)
    return []
  }
}

const debounce = (fn, delay) => {
  let timeoutId
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const debouncedSearch = debounce(async (query) => {
  if (query) {
    suggestions.value = await searchAddress(query)
    console.log(suggestions)
  }
}, 500)

watch(searchQuery, (newValue) => {
  debouncedSearch(newValue)
})

const propertyType = ref('primaryHome')
const isForIframe = ref(false)

const handleSubmit = () => {
  let address = searchQuery.value
  let urlParameters = new URLSearchParams()
  let cookieParamString = ''

  // Set the address parameter
  urlParameters.set('address', address)

  // Check current URL parameters first
  const currentUrlParams = new URLSearchParams(window.location.search)

  // If URL has parameters, use those (except on blog pages)
  if (currentUrlParams.toString() && !isBlogPage()) {
    TRACKING_PARAMS.forEach((param) => {
      const urlValue = currentUrlParams.get(param)
      if (urlValue) {
        urlParameters.set(param, urlValue)
      }
    })
  }
  // If no URL parameters or on blog page, build string from cookies
  else {
    TRACKING_PARAMS.forEach((param) => {
      const cookieValue = getCookie(param)
      if (cookieValue) {
        if (cookieParamString) cookieParamString += '&'
        cookieParamString += `${param}=${encodeURIComponent(cookieValue)}`
      }
    })
  }

  let entryPointUrl = `${
    props.baseUrl
  }/consumer-app?${urlParameters.toString()}`

  // Add cookie parameters if they exist and no URL parameters were present
  if (cookieParamString && (!currentUrlParams.toString() || isBlogPage())) {
    entryPointUrl += (urlParameters.toString() ? '&' : '') + cookieParamString
  }

  // Add blog payload parameter if it's a blog page
  if (isBlogPage()) {
    entryPointUrl += (entryPointUrl.includes('?') ? '&' : '?') + 'payload=blog'
  }

  console.log('Final URL:', entryPointUrl)
  window.open(entryPointUrl)
}
</script>
