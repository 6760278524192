<template>
  <div class="shadow-sm max-h-60">
    <label
      :for="filteredAttrs['id']"
      class="block text-sm font-medium text-gray-700"
      >{{ labelText }}</label
    >
    <select
      :id="filteredAttrs['id']"
      :name="filteredAttrs['name']"
      v-bind="filteredAttrs"
      @input="onInput"
      :value="modelValue"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-blue focus:border-brand-blue sm:text-sm rounded-md"
    >
      <option value="" disabled selected>Select your agency...</option>
      <option
        v-for="(item, index) in arrOptions"
        :key="index"
        :value="item.autoRegistrationLink"
      >
        <span v-if="item.autoAgencyName">{{ item.autoAgencyName }}</span>

        <span
          >{{ item.city }} - {{ item.addr1 }}, {{ item.city }},
          {{ item.state }} {{ item.zip }} - ({{ item.agencyNo }})</span
        >
      </option>
    </select>
  </div>
</template>
<script setup>
import { useAttrs } from "vue";
const { ...filteredAttrs } = useAttrs();

defineProps({
  labelText: String,
  modelValue: [String, Number, Date],
  arrOptions: Array,
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>
<script>
export default {
  inheritAttrs: false,
};
</script>
